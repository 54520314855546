import request from "@/utils/request";
const api = {
  getquotationlist: "/dsmcore/api/quotation/list", // 查询报价列表
  addquotation: "/dsmcore/api/quotation/add", //添加报价
  updatequotation: "/dsmcore/api/quotation/update", //编辑报价
  deletequotation: "/dsmcore/api/quotation/delete", //删除报价
  getquotationDetaillist: "/dsmcore/api/quotationDetail/list", //查询报价详细信息
  addquotationDetaillist: "/dsmcore/api/quotationDetail/add", //添加报价详细信息
  updatequotationDetaillist: "/dsmcore/api/quotationDetail/update", //更新报价详细信息
  deletequotationDetaillist: "/dsmcore/api/quotationDetail/delete", //删除报价详细信息
};
export function GetQuotationList(parameter) {
  return request({
    url: api.getquotationlist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddQuotation(parameter) {
  return request({
    url: api.addquotation,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateQuotation(parameter) {
  return request({
    url: api.updatequotation,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteQuotation(parameter) {
  return request({
    url: api.deletequotation,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function GetQuotationDetailList(parameter) {
  return request({
    url: api.getquotationDetaillist,
    method: "post",
    params: parameter,
    //   data: parameter,
  });
}
export function AddQuotationDetailList(parameter) {
  return request({
    url: api.addquotationDetaillist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function UpdateQuotationDetailList(parameter) {
  return request({
    url: api.updatequotationDetaillist,
    method: "post",
    // params: parameter,
    data: parameter,
  });
}
export function DeleteQuotationDetailList(parameter) {
  return request({
    url: api.deletequotationDetaillist,
    method: "post",
    params: parameter,
    // data: parameter,
  });
}
